import {ContainerBreakage} from 'Query/ProductList.type';

/** @namespace Steinkrueger/Util/ProductIncrement/getProductIncrement */
export const getProductIncrement = (container_breakage: ContainerBreakage, productScope: string) => {
    switch (productScope) {
    case 'kolli':
        return container_breakage.kolli_increment_step;
    case 'piece':
        return container_breakage.piece_increment_step;
    case 'kg':
        return container_breakage.kg_increment_step;
    default:
        return 1;
    }
};
