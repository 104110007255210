import React, {PureComponent} from 'react';
import Slider from 'react-slick';

import {SlickSliderComponentProps, SlickSliderComponentState} from './SlickSlider.type';

import './SlickSlider.style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/** @namespace Steinkrueger/Component/SlickSlider/Component */
export class SlickSliderComponent extends PureComponent<SlickSliderComponentProps, SlickSliderComponentState> {
    static defaultProps: Partial<SlickSliderComponentProps> = {
        activeImage: 0,
        showCrumbs: false,
        showArrows: true,
        showCounter: false,
        isInteractionDisabled: false,
        mix: {},
        isVertical: false,
        isHeightTransitionDisabledOnMount: false,
        sliderHeight: 0,
        sliderRef: null,
    };

    render() {
        const {
            children,
            slide_speed,
            slides_to_display,
            slides_to_scroll,
            slides_to_display_mobile,
            slides_to_scroll_mobile,
            slides_to_display_tablet,
            slides_to_scroll_tablet,
            title,
            isHomeSlider,
        } = this.props;
        const defaultSpeed = 600;
        const autoplaySpeed = isHomeSlider ? Number(slide_speed) : 0;

        const settings = {
            dots: false,
            infinite: !!isHomeSlider,
            autoplay: !!isHomeSlider,
            autoplaySpeed,
            slidesToShow: Number(slides_to_display),
            slidesToScroll: Number(slides_to_scroll),
            speed: defaultSpeed,
            adaptiveHeight: !!isHomeSlider,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: Number(slides_to_display_tablet),
                        slidesToScroll: Number(slides_to_scroll_tablet),
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: Number(slides_to_display_mobile),
                        slidesToScroll: Number(slides_to_scroll_mobile),
                    },
                },
            ],
        };

        return (
            <div block="SlickSlider">
                { title && <h2>{ title }</h2> }
                <Slider { ...settings }>
                    { children }
                </Slider>
            </div>
        );
    }
}

export default SlickSliderComponent;
