import {PureComponent} from 'react';
import Countdown from 'react-countdown';

import NextDayDeliveryIcon from 'Component/NextDayDeliveryIcon';
import {ProductTimerComponentProps} from 'Component/ProductTimer/ProductTimer.type';
import {ReactElement} from 'Type/Common.type';
import {formatInTwoDigits} from 'Util/DateFormat';

import './ProductTimer.style.scss';

/** @namespace Steinkrueger/Component/ProductTimer/Component */
export class ProductTimerComponent extends PureComponent<ProductTimerComponentProps> {
    renderMessage(): ReactElement {
        return (
            <div block="ProductTimer" elem="Message">
                <div block="ProductTimer" elem="Icon">
                    <NextDayDeliveryIcon />
                </div>
                <p>{ __('Next-day delivery still available for:') }</p>
            </div>
        );
    }

    // @ts-ignore
    renderer({ hours, minutes, seconds }): ReactElement {
        const formattedHours = formatInTwoDigits(hours);
        const formattedMinutes = formatInTwoDigits(minutes);
        const formattedSeconds = formatInTwoDigits(seconds);

        return (
            <div block="ProductTimer-Counter">
                <span block="ProductTimer" elem="Number">{ formattedHours }</span>
                <span>:</span>
                <span block="ProductTimer" elem="Number">{ formattedMinutes }</span>
                <span>:</span>
                <span block="ProductTimer" elem="Number">{ formattedSeconds }</span>
            </div>
        );
    }

    renderTimer(): ReactElement {
        const {
            hideTimer,
            time,
        } = this.props;

        return (
            <div block="ProductTimer" elem="CounterWrapper">
                <Countdown
                  date={ time }
                  onComplete={ hideTimer }
                  renderer={ this.renderer }
                />
                <div block="ProductTimer" elem="CounterLabels">
                    <span>{ __('Hours') }</span>
                    <span>{ __('Minutes') }</span>
                    <span>{ __('Seconds') }</span>
                </div>
            </div>

        );
    }

    render(): ReactElement {
        return (
            <div block="ProductTimer">
                { this.renderMessage() }
                { this.renderTimer() }
            </div>
        );
    }
}

export default ProductTimerComponent;
