/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import {ComponentType} from 'react';
import {RouteComponentProps, withRouter} from 'react-router';

import {ARROW_SAFE_AREA, CAROUSEL_ITEM_GAP, CAROUSEL_ITEM_WIDTH} from 'Component/CarouselScroll/CarouselScroll.config';
import {MediaType} from 'Component/ProductGallery/ProductGallery.config';
import {ProductGalleryComponentProps, ProductGalleryComponentState} from 'Component/ProductGallery/ProductGallery.type';
import Slider from 'Component/Slider';
import {MediaGalleryEntry} from 'Query/ProductList.type';
import {
    CarouselScroll,
    ProductGalleryComponent as SourceProductGalleryComponent,
    VideoPopup,
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import {ReactElement} from 'Type/Common.type';

import './ProductGallery.style';

export {
    CarouselScroll,
    VideoPopup,
};

/** @namespace Steinkrueger/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    state: ProductGalleryComponentState = {
        scrollEnabled: true,
        slidesCount: 3,
        prevZoom: false,
    };

    _calculateGallerySize(): void {
        const { isMobile } = this.props;
        const ref = this.galleryRef.current;

        if (!ref || isMobile) {
            return;
        }
        const { width } = ref.getBoundingClientRect();

        const slidesCount = Math.floor((width - ARROW_SAFE_AREA * 2 + CAROUSEL_ITEM_GAP)
            / (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_GAP));

        this.setState({ slidesCount });
    }

    renderSlide(media: MediaGalleryEntry, index: number): ReactElement {
        const { activeImage } = this.props;
        const { media_type } = media;
        const { isPrefetchValueUsed = false } = window;

        if (activeImage === undefined) {
            return null;
        }

        if (index !== activeImage && isPrefetchValueUsed) {
            return <div key={ index } />;
        }

        switch (media_type) {
        case MediaType.IMAGE:
            return this.renderImage(media, index);
        case MediaType.VIDEO:
            return this.renderVideo(media, index);
        case MediaType.PLACEHOLDER:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    renderSlider(): ReactElement {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCounter={ isMobile }
                  showCrumbs
                  showArrows={ isMobile && isMoreThanOnePhoto }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }
}

export default withRouter(
    ProductGalleryComponent as unknown as ComponentType<
    RouteComponentProps & ProductGalleryComponentProps
    >,
);
