import {lazy, Suspense} from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    HomeSlider,
    NewProducts,
    ProductListWidget,
    RecentlyViewedWidget,
    WidgetFactoryComponent as SourceWidgetFactoryComponent,
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';
import { ReactElement } from 'Type/Common.type';

import { Widget } from './WidgetFactory.config';
import { WidgetFactoryComponentRenderMap } from './WidgetFactory.type';

export {
    ProductListWidget,
    NewProducts,
    HomeSlider,
    RecentlyViewedWidget,
};

export const RelatedProducts = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "custom-widget-component" */
    'Component/RelatedProducts'
));

export const LatestBlogPosts = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "custom-widget-component" */
    'Component/LatestBlogPosts'
));

/** @namespace Steinkrueger/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactoryComponent {
    renderMap: WidgetFactoryComponentRenderMap = {
        [Widget.SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback,
        },
        [Widget.NEW_PRODUCTS]: {
            component: NewProducts,
        },
        [Widget.CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget,
        },
        [Widget.RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget,
        },
        RELATED_PRODUCTS: {
            component: RelatedProducts,
        },
        RECENT_POSTS: {
            component: LatestBlogPosts,
        },
    };

    renderContent(): ReactElement {
        const {
            type,
            sliderId = 0,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            onLoad,
            subtitle,
        } = this.props;
        const {
            component: Widget,
            fallback = () => <div />,
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <Suspense fallback={ fallback() }>
                    <RenderWhenVisible>
                        <Widget
                          sliderId={ sliderId }
                          displayType={ displayType }
                          productsCount={ productsCount }
                          showPager={ !!showPager }
                          storeId={ storeId }
                          title={ title }
                          subtitle={ subtitle }
                          conditionsEncoded={ conditionsEncoded }
                          onLoad={ onLoad }
                        />
                    </RenderWhenVisible>
                </Suspense>
            );
        }

        return null;
    }

    render(): ReactElement {
        return this.renderContent();
    }
}

export default WidgetFactoryComponent;
