import {
    ProductActionsComponent as SourceProductActionsComponent,
} from 'SourceComponent/ProductActions/ProductActions.component';
import {ReactElement} from 'Type/Common.type';

/** @namespace Steinkrueger/Component/ProductWishlist/Component */
export class ProductWishlistComponent extends SourceProductActionsComponent {
    render(): ReactElement {
        return (
            <div block="ProductWishlist" elem="Wrapper">
                { this.renderWishlistButton() }
            </div>
        );
    }
}

export default ProductWishlistComponent;
