import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import SlickSlider from './SlickSlider.component';
import {
    SlickSliderContainerMapDispatchProps,
    SlickSliderContainerMapStateProps,
} from './SlickSlider.type';

/** @namespace Steinkrueger/Component/SlickSlider/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): SlickSliderContainerMapStateProps => ({
    device: state.ConfigReducer.device,
});

/** @namespace Steinkrueger/Component/SlickSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (): SlickSliderContainerMapDispatchProps => ({});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SlickSlider);
