import { Field, Query } from '@tilework/opus';

/** @namespace Steinkrueger/Query/DeliveryDate/Query */
export class DeliveryDateQuery {
    getQuery(options: { productId: number }): Query<'getAvailabilityData', string> {
        const { productId } = options;

        return new Query<'getAvailabilityData', any>('getAvailabilityData')
            .addArgument('productId', 'Int!', productId)
            .addFieldList(this._getDeliveryDataFields());
    }

    _getDeliveryDataFields(): Array<
    Field<'data', string, true>
    > {
        return [
            this._getData(),
        ];
    }

    _getData(): Field<'data', string, true> {
        return new Field<'data', string, true>('data', true);
    }
}

export default new DeliveryDateQuery();
