import { connect } from 'react-redux';

import {
    mapDispatchToProps,
} from 'Component/Product/Product.container';
import ProductWishlist from 'Component/ProductWishlist/ProductWishlist.component';
import {
    mapStateToProps,
    ProductActionsContainer as SourceProductWishlistContainer,
} from 'SourceComponent/ProductActions/ProductActions.container';
import {ReactElement} from 'Type/Common.type';

/** @namespace Steinkrueger/Component/ProductWishlist/Container */
export class ProductWishlistContainer extends SourceProductWishlistContainer {
    render(): ReactElement {
        return (
            <ProductWishlist
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistContainer);
