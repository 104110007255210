/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SlickSlider from 'Component/SlickSlider';

import Loader from 'Component/Loader/Loader.component';
import ProductCard from 'Component/ProductCard';
import { DeviceType } from 'Type/Device.type';
import { ItemsType } from 'Type/ProductList.type';

import {
    PageBuilderStyles,
    ProductCardsPropsType,
    ProductSliderConfigType
} from '../../type/ProductSlider.type';

import 'slick-carousel/slick/slick.css';
import './ProductSlider.style';
import ContentWrapper from 'Component/ContentWrapper';

/** @namespace Scandiweb/AdobePageBuilder/Component/ProductSlider/Component */
export class ProductSliderComponent extends PureComponent {
    static propTypes = {
        sliderConfig: ProductSliderConfigType.isRequired,
        products: ItemsType.isRequired,
        pageBuilderStyles: PageBuilderStyles.isRequired,
        device: DeviceType.isRequired,
        productCardProps: ProductCardsPropsType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        getSliderSettings: PropTypes.func.isRequired
    };

    render() {
        const {
            products,
            pageBuilderStyles: {
                classes = '',
                dataPbStyle
            },
            productCardProps,
            getSliderSettings,
            isLoading
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        return (
            <div className={ `ProductSlider ${classes}` } data-pb-style={ dataPbStyle }>
                <ContentWrapper
                    mix={ { block: 'ProductLinks' } }
                    wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
                    label={ __('Linked products') }
                >
                    <ul block="ProductLinks" elem="Slider">
                        <SlickSlider { ...getSliderSettings() }>
                            { products.map((product) => (
                                <ProductCard
                                    product={ product }
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={ (product.uid) }
                                    // layout="list"
                                    { ...productCardProps }
                                />
                            )) }
                        </SlickSlider>
                    </ul>
                </ContentWrapper>
            </div>
        );
    }
}

export default ProductSliderComponent;
