import {
    Widget as SourceWidget,
} from 'SourceComponent/WidgetFactory/WidgetFactory.config';

export enum CustomWidget {
    RELATED_PRODUCTS = 'RELATED_PRODUCTS',
    RECENT_POSTS = 'RECENT_POSTS',
}

export const Widget = {
    ...SourceWidget,
    ...CustomWidget,
};
