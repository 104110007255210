/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import ProductListQuery from 'Query/ProductList.query';
import { DeviceType } from 'Type/Device.type';
import { getIndexedProducts } from 'Util/Product';
import DataContainer from 'Util/Request/DataContainer';

import { PageBuilderStyles, ProductSliderConfigType } from '../../type/ProductSlider.type';
import sortMapper from '../../util/sortMapper';
import ProductSlider from './ProductSlider.component';

/** @namespace Scandiweb/AdobePageBuilder/Component/ProductSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandiweb/AdobePageBuilder/Component/ProductSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatcher) => ({

});
/** @namespace Scandiweb/AdobePageBuilder/Component/ProductSlider/Container */
export class ProductSliderContainer extends DataContainer {
    static defaultProps = {
        pageBuilderStyles: {
            classes: '',
            dataPbStyle: '',
        },
    };

    static propTypes = {
        sliderConfig: ProductSliderConfigType.isRequired,
        productQueryParams: PropTypes.shape({
            conditionsEncoded: PropTypes.string.isRequired,
            productsCount: PropTypes.string.isRequired,
            sortOrder: PropTypes.string.isRequired,
        }),
        pageBuilderStyles: PageBuilderStyles,
        device: DeviceType.isRequired
    };

    updateProductListItems = this.updateProductListItems.bind(this);

    setIsLoadingFalse = this.setIsLoadingFalse.bind(this);

    state = {
        isLoading: true,
        products: []
    };

    /**
     * @param {*} props
     */
    __construct(props) {
        super.__construct(props, 'ProductSliderContainer', false);
    }

    componentDidMount() {
        this.requestProductList();
    }

    containerFunctions ={
        getSliderSettings: this.getSliderSettings.bind(this)
    };

    containerProps() {
        const { isLoading, products } = this.state;
        const { device, sliderConfig, pageBuilderStyles } = this.props;

        return {
            isLoading,
            products,
            sliderConfig,
            pageBuilderStyles,
            device,
            productCardProps: {
                siblingsHaveBrands: true,
                siblingsHavePriceBadge: true,
                siblingsHaveTierPrice: true,
                siblingsHaveConfigurableOptions: true
            }

        };
    }

    getSliderSettings() {
        return {
            slides_to_display: 5.5,
            slides_to_scroll: 1,
            slides_to_display_mobile: 1.5,
            slides_to_scroll_mobile: 1,
            slides_to_display_tablet: 2.5,
            slides_to_scroll_tablet: 1,
        };
    }

    requestProductList() {
        const {
            productQueryParams: {
                conditionsEncoded,
                productsCount,
                sortOrder
            }
        } = this.props;

        const options = {
            isNext: false,
            noAttributes: false,
            noVariants: false,
            args: {
                sort: sortMapper(sortOrder),
                filter: {
                    conditions: conditionsEncoded
                },
                pageSize: productsCount
            }
        };

        this.fetchData(
            [ProductListQuery.getQuery(options)],
            this.updateProductListItems,
            this.setIsLoadingFalse
        );
    }

    setIsLoadingFalse() {
        this.setState({ isLoading: false });
    }

    updateProductListItems(data) {
        const {
            products: {
                items
            } = {}
        } = data;

        this.setState({
            isLoading: false,
            products: getIndexedProducts(items)
        });
    }

    render() {
        return (
            <ProductSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSliderContainer);
