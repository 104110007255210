/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import {Directions} from 'Component/ChevronIcon/ChevronIcon.config';
import {ANIMATION_DURATION} from 'Component/Slider/Slider.config';
import {SliderComponentProps} from 'Component/Slider/Slider.type';
import SliderArrowIcon from 'Component/SliderArrowIcon';
import {
    SliderComponent as SourceSliderComponent,
} from 'SourceComponent/Slider/Slider.component';
import {ReactElement} from 'Type/Common.type';

import './Slider.override.style';

/** @namespace Steinkrueger/Component/Slider/Component */
export class SliderComponent extends SourceSliderComponent {
    componentDidUpdate(prevProps: SliderComponentProps): void {
        const { activeImage: prevActiveImage } = prevProps;
        const { activeImage } = this.props;
        const { isInitialized } = this.state;

        if (activeImage !== prevActiveImage && this.getIsSlider()) {
            const newTranslate = -activeImage * this.getSlideWidth() * this.getDir();

            if (!isInitialized) {
                this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION));
                this.setTranlateXStyle(newTranslate);
                this.setState({ isInitialized: true });

                return;
            }

            this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION));
            this.setTranlateXStyle(newTranslate);
        }
    }

    renderArrows(): ReactElement {
        const { showArrows, activeImage, children } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <SliderArrowIcon direction={ Directions.LEFT } />
                </button>
                { this.renderCounter() }
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <SliderArrowIcon direction={ Directions.RIGHT } />
                </button>
            </>
        );
    }

    renderCounter(): ReactElement {
        const { children, showCounter, activeImage } = this.props;

        if (!showCounter || children.length <= 1) {
            return null;
        }

        return (
            <div
              block="Slider"
              elem="Counter"
            >
                <span block="Slider" elem="ActiveSlide">{ Math.ceil(activeImage + 1) }</span>
                <span block="Slider" elem="Divider" />
                { children.length }
            </div>
        );
    }

    render(): ReactElement {
        const { mix } = this.props;

        return (
            <div
              block="Slider"
              mix={ mix }
              ref={ this.getSliderRef() }
            >
                    { this.renderSliderContent() }
                    { this.renderCrumbs() }
                    <div block="SliderNavigation">
                        { this.renderArrows() }
                    </div>
            </div>
        );
    }
}

export default SliderComponent;
