/* eslint-disable consistent-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import { DomElement } from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';

import {
    HIGH_PRIORITY_ELEMENTS,
    HtmlComponent as SourceHtmlComponent,
} from 'SourceComponent/Html/Html.component';

export {
    HIGH_PRIORITY_ELEMENTS,
};

/** @namespace Steinkrueger/Component/Html/Component */
export class HtmlComponent extends SourceHtmlComponent {
    replaceDiv({ attribs, children }: DomElement): JSX.Element {
        const attributes = attributesToProps(attribs);

        return (
            <div { ...attributes }>
                { domToReact(children, this.parserOptions) }
            </div>
        );
    }
}

export default HtmlComponent;
