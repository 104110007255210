import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import './SelectedIcon.style';

/** @namespace Steinkrueger/Component/SelectedIcon/Component */
export class SelectedIconComponent extends PureComponent {
    render(): ReactElement {
        return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              block="SelectedIcon"
            >
                <circle cx="10" cy="10" r="10" fill="#003E90" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.3034 7.43936L13.8892 6.02515L8.93936 10.9749L6.11088
                       8.14646L4.69666 9.56067L8.9393 13.8033L8.93935 13.8033L8.93941
                       13.8033L15.3034 7.43936Z"
                  fill="white"
                />
            </svg>
        );
    }
}

export default SelectedIconComponent;
