import {ConfigurableProductSelectedVariantValue} from 'Util/Product/Product.type';

/** @namespace Steinkrueger/Util/ConfigurableProduct/getNewParameters */
export const getNewParameters = (
    parameters: Record<string, string>,
    key: string,
    value: ConfigurableProductSelectedVariantValue = '',
): Record<string, string> => ({
    ...parameters,
    [key]: value.toString(),
});
