/** @namespace Steinkrueger/Util/GetNexWeekDates/getNextWeekDates */
export const getNextWeekDates = (): string[] => {
    const nextWeek = ['0', '1', '2', '3', '4', '5', '6'];
    const nextWeekDates: string[] = [];

    nextWeek.forEach((element) => {
        const today = new Date();
        const tomorrow = new Date(today);
        const formattedDateNumber = parseInt(element, 10) + 1;
        tomorrow.setDate(today.getDate() + formattedDateNumber);
        // eslint-disable-next-line no-magic-numbers
        nextWeekDates.push(tomorrow.toISOString().slice(0, 10));
    });

    return nextWeekDates;
};
