import {
    NavigationTabsMap as SourceNavigationTabsMap,
} from 'SourceComponent/NavigationTabs/NavigationTabs.config';

export enum NavigationTabsMap {
    HOME_TAB = 'HOME_TAB',
    MENU_TAB = 'MENU_TAB',
    ACCOUNT_TAB = 'ACCOUNT_TAB',
    CART_TAB = 'CART_TAB',
    CHECKOUT_TAB = 'CHECKOUT_TAB',
    WISHLIST_TAB = 'WISHLIST_TAB',
}

export type NavigationTabsMapType = SourceNavigationTabsMap & NavigationTabsMap;
