import {ProductType} from 'Component/Product/Product.config';
import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import {ReactElement} from 'Type/Common.type';

import './ProductPrice.override.style';

/** @namespace Steinkrueger/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    render(): ReactElement {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
                finalPriceExclTax: {
                    value: finalPriceExclTaxValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;
        const isExclTax = finalPriceExclTaxValue === finalPriceValue;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isExclTax && (
                    <span block="ProductPrice" elem="ExclTaxLabel">
                        { __('excl. VAT') }
                    </span>
                ) }
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== ProductType.BUNDLE && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
