import Loader from 'Component/Loader';
import {ContentObject} from 'Component/ProductCard/ProductCard.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import {TextPlaceHolderLength} from 'Component/TextPlaceholder/TextPlaceholder.config';
import {CategoryPageLayout} from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import {ReactElement} from 'Type/Common.type';
import {isSignedIn} from 'Util/Auth/IsSignedIn';
import {getErpPriceValue} from 'Util/ErpPrice';

import './ProductCard.override.style';

/** @namespace Steinkrueger/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    contentObject: ContentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this),
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderErpPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this),
        },
    };

    renderCardName(header = true, dynamic = false): ReactElement {
        const {
            product: { name },
            productName,
            device: { isMobile },
            layout,
        } = this.props;

        const nameToRender = dynamic ? productName : name;
        // eslint-disable-next-line no-magic-numbers
        const maxLength = isMobile ? 40 : 55;
        const result = nameToRender && nameToRender.length > maxLength
            ? `${nameToRender.substring(0, maxLength).trim() }...`
            : nameToRender;

        if (!header) {
            if (layout === CategoryPageLayout.LIST) {
                return (
                    <p block={ this.className } elem="Name">
                        <TextPlaceholder content={ nameToRender } length={ TextPlaceHolderLength.MEDIUM } />
                    </p>
                );
            }

            return (
                <p block={ this.className } elem="Name">
                    <TextPlaceholder content={ result } length={ TextPlaceHolderLength.MEDIUM } />
                </p>
            );
        }

        return (
            <h1 block={ this.className } elem="Title" itemProp="name">
                <TextPlaceholder content={ result } length={ TextPlaceHolderLength.MEDIUM } />
            </h1>
        );
    }

    renderLabel(): ReactElement {
        const { product } = this.props;
        const isFreshCut = product.is_fresh_cut ? __('Fresh Cut') : '';

        if (!isFreshCut) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Label">
                { isFreshCut }
            </div>
        );
    }

    renderCardContent(): ReactElement {
        const { renderContent, product: { name } } = this.props;
        const isLogged = isSignedIn();

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderCardName(false) }
                        { this.renderLabel() }
                        { isLogged && this.renderErpPrice() }
                    </div>
                </div>
            ))
        );
    }

    renderErpPrice(): ReactElement {
        const { erpPrice = '', product } = this.props;

        if (product.type_id === 'configurable' || !getErpPriceValue(erpPrice)) {
            return null;
        }

        if (!erpPrice) {
            return (
                <span block="ProductCard" elem="Price">
                    <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
                </span>
            );
        }

        return (
            <span block="ProductCard" elem="Price">
                <span block="ProductPrice" elem="PriceValue">{ erpPrice }</span>
            </span>
        );
    }

    renderCardListContent(): ReactElement {
        const {
            renderContent, product: { name },
        } = this.props;

        const isLogged = isSignedIn();

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="Link" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderCardName(false) }
                        { this.renderLabel() }
                        { isLogged && this.renderErpPrice() }
                    </div>
                </div>
            ))
        );
    }

    render(): ReactElement {
        const {
            mix,
            isLoading,
            layout,
        } = this.props;

        if (layout === CategoryPageLayout.LIST) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
            </li>
        );
    }
}

export default ProductCardComponent;
