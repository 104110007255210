import {
    ImageZoomPopupComponent as SourceImageZoomPopupComponent,
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Steinkrueger/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopupComponent {}

export default ImageZoomPopupComponent;
