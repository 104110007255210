import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { Directions } from './SliderArrowIcon.config';
import { SliderArrowIconComponentProps } from './SliderArrowIcon.type';

import './SliderArrowIcon.style';

/** @namespace Steinkrueger/Component/SliderArrowIcon/Component */
export class SliderArrowIconComponent extends PureComponent<SliderArrowIconComponentProps> {
    static defaultProps: Partial<SliderArrowIconComponentProps> = {
        direction: Directions.RIGHT,
    };

    render(): ReactElement {
        const { direction } = this.props;

        return (
                <svg
                  block="SliderArrowIcon"
                  mods={ { direction } }
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                >
                    <rect
                      x="-1"
                      y="1"
                      width="42"
                      height="42"
                      rx="21"
                      transform="matrix(-1 0 0 1 42 0)"
                      strokeWidth="2"
                    />
                    <path d="M23 17L28 22L23 27" strokeWidth="2" strokeLinecap="round" />
                    <path
                      d="M19.2071 22.7071C19.5976 22.3166 19.5976 21.6834 19.2071 21.2929C18.8166 20.9024
                      18.1834 20.9024 17.7929 21.2929L19.2071 22.7071ZM23.7071 27.7071L24.4142 27L23 25.5858L22.2929
                      26.2929L23.7071 27.7071ZM17.7929 21.2929C17.3077 21.7781 16 23.0443 16 25H18C18
                      23.9557 18.6923 23.2219 19.2071 22.7071L17.7929 21.2929ZM16 25C16 27.0345 17.3859
                      28.3598 18.9627 28.8328C20.5101 29.297 22.4088 29.0054 23.7071 27.7071L22.2929
                      26.2929C21.5912 26.9946 20.4899 27.203 19.5373 26.9172C18.6141
                      26.6402 18 25.9655 18 25H16Z"
                    />
                    <path
                      d="M19.2071 21.2929C19.5976 21.6834 19.5976 22.3166 19.2071 22.7071C18.8166 23.0976 18.1834
                      23.0976 17.7929 22.7071L19.2071 21.2929ZM23.7047 16.3241L24.4142 17.0288L23.0048 18.4477L22.2953
                      17.743L23.7047 16.3241ZM17.7929 22.7071C17.3109 22.2251 16 20.9576 16 19.0201H18C18 20.0424
                      18.6891 20.7749 19.2071 21.2929L17.7929 22.7071ZM16 19.0201C16 16.9912 17.391 15.6749
                      18.9644 15.2061C20.5093 14.7457 22.4061 15.0342 23.7047 16.3241L22.2953 17.743C21.5939
                      17.0463 20.4907 16.8382 19.5356 17.1228C18.609 17.3989 18 18.0691 18 19.0201H16Z"
                    />
                </svg>
        );
    }
}

export default SliderArrowIconComponent;
