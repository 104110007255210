import {
    ChevronIconComponent as SourceChevronIconComponent,
} from 'SourceComponent/ChevronIcon/ChevronIcon.component';
import {ReactElement} from 'Type/Common.type';

/** @namespace Steinkrueger/Component/ChevronIcon/Component */
export class ChevronIconComponent extends SourceChevronIconComponent {
    render(): ReactElement {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="18"
              height="18"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M17 1L9 9L1 0.999999" stroke="#003E90" strokeWidth="2" strokeLinecap="round" />
            </svg>
        );
    }
}

export default ChevronIconComponent;
