/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
import { SortDirections } from 'Route/CategoryPage/CategoryPage.config';

/**
 *
 * @param sortOrder
 * @returns {{sortDirection: string, sortKey: string}}
 */
export const sortMap = (sortOrder) => {
    switch (sortOrder) {
        case 'position_by_sku': return {sortKey: 'position',sortDirection: SortDirections.asc};
        case 'position': return {sortKey: 'position',sortDirection: SortDirections.desc};
        case 'date_newest_top': return {sortKey: 'entity_id',sortDirection: SortDirections.desc};
        case 'date_oldest_top': return {sortKey: 'entity_id',sortDirection: SortDirections.asc};
        case 'name_ascending': return {sortKey: 'name',sortDirection: SortDirections.asc};
        case 'name_descending': return {sortKey: 'name',sortDirection: SORT_DIRECTION_TYPE.desc};
        case 'sku_ascending': return {sortKey: 'sku',sortDirection: SortDirections.asc};
        case 'sku_descending': return {sortKey: 'sku',sortDirection: SortDirections.desc};
        case 'low_stock_first': return {sortKey: 'quantity_and_stock_status',sortDirection: SortDirections.asc};
        case 'high_stock_first': return {sortKey: 'quantity_and_stock_status',sortDirection: SortDirections.desc};
        case 'price_high_to_low': return {sortKey: 'price',sortDirection: SortDirections.desc};
        case 'price_low_to_high': return {sortKey: 'price',sortDirection: SortDirections.asc};

        default: return {sortKey: 'none', sortDirection: 'ASC'}
    }
}

export default sortMap;
