/* eslint-disable max-len */

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

/** @namespace Steinkrueger/Component/NextDayDeliveryIcon/Component */
export class NextDayDeliveryIconComponent extends PureComponent {
    render(): ReactElement {
        return (
            <svg width="53" height="32" viewBox="0 0 53 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask
                  id="mask0_1054_19417"
                  maskUnits="userSpaceOnUse"
                  x="21"
                  y="0"
                  width="32"
                  height="32"
                >
                    <path d="M21.386 0H53V32H21.386V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1054_19417)">
                    <path
                      d="M49.6036 27.25H52.0735V18.5L48.3687 12.25H42.1941V27.25H44.6639"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M49.6038 27.25C49.6038 28.6307 48.498 29.75 47.1339 29.75C45.7698 29.75 44.6641 28.6307
                         44.6641 27.25C44.6641 25.8693 45.7698 24.75 47.1339 24.75C48.498 24.75 49.6038
                          25.8693 49.6038 27.25Z"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.7217 27.25C29.7217 28.6307 28.6159 29.75 27.2518 29.75C25.8877 29.75 24.782
                         28.6307 24.782 27.25C24.782 25.8693 25.8877 24.75 27.2518 24.75C28.6159 24.75
                          29.7217 25.8693 29.7217 27.25Z"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M52.0735 18.5H45.8989V12.25"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M49.6042 22.25H52.0741"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M30.339 6H26.0168C23.9707 6 22.312 4.32106 22.312 2.25H30.9565C33.0026 2.25 34.6612 3.92894 34.6612 6"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M30.3391 9.75H28.4868C26.4407 9.75 24.782 8.07106 24.782 6H30.3391"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M34.6615 6V13.5H30.9567C28.9106 13.5 27.252 11.8211 27.252 9.75H30.3393"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.7214 27.25H42.1942V11C42.1942 9.61925 41.0884 8.5 39.7243 8.5H34.6611"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.7308 8.5H22.312V27.25H24.7819"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                </g>
                <mask
                  id="mask1_1054_19417"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="4"
                  width="27"
                  height="28"
                >
                    <path d="M0 4.70565H26.0351V31.0586H0V4.70565Z" fill="white" />
                </mask>
                <g mask="url(#mask1_1054_19417)">
                    <path
                      d="M16.0893 27.1772C13.6364 27.1772 11.3303 26.2104 9.59595 24.4548C7.86146 22.6992 6.90625 20.365 6.90625
                         17.8821C6.90625 15.3992 7.86146 13.065 9.5959 11.3094C11.3303 9.55382 13.6364 8.58694 16.0893
                          8.58694C18.5422 8.58694 20.8483 9.55382 22.5827 11.3094C24.3172 13.065 25.2724 15.3992 25.2724
                           17.8821C25.2724 20.365 24.3172 22.6992 22.5827 24.4548C20.8483 26.2104 18.5422 27.1772 16.0893
                            27.1772Z"
                      fill="#FFD7AA"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.4534 19.3863H17.106C17.106 19.3863 19.4078 15.1631 19.7876 14.6914C20.1717 14.2144 20.5354 14.5119
                         20.57 14.9327C20.6047 15.3534 20.5527 21.4546 20.5527 21.4546"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5287 21.4384C14.5287 21.4384 11.1604 21.4813 11.0008 21.4274C10.8413 21.3736 11.2567 21.0892
                         13.4651 17.8953C13.8775 17.2989 14.1045 16.7956 14.2011 16.374L14.2353 16.103C14.2353 15.1127 13.4422
                          14.3098 12.4638 14.3098C11.6029 14.3098 10.8855 14.9314 10.7256 15.7554"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.33101 17.8823H6.90625"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.2724 17.8823H23.8477"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0894 25.7351V27.1772"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0894 8.58714V10.0293"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.90605 17.8857H4.88843"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.10974 14.7979H2.85474"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.82757 11.7095H0.77002"
                      stroke="#121212"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                </g>
            </svg>
        );
    }
}

export default NextDayDeliveryIconComponent;
