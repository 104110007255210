/* eslint-disable max-lines */

import {Suspense} from 'react';

import {FieldType} from 'Component/Field/Field.config';
import {ProductType} from 'Component/Product/Product.config';
import ProductTimerComponent from 'Component/ProductTimer';
import SelectedIcon from 'Component/SelectedIcon';
import SlickSlider from 'Component/SlickSlider';
import {
    AddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductComponent as SourceProductComponent,
    ProductConfigurableAttributes,
    ProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';
import {ReactElement} from 'Type/Common.type';
import {europeTime, formattedDate} from 'Util/DateFormat';
import {getProductIncrement} from 'Util/ProductIncrement';
import {ValidationInputTypeNumber} from 'Util/Validator/Config';

import { DeliveryDataItem } from './Product.type';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    AddToCart,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
};

/** @namespace Steinkrueger/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderCustomAndBundleOptions(): ReactElement {
        const { product: { type_id }, configFormRef } = this.props;
        const {
            product: {
                options,
            },
            getDeliveryDate,
            availableDeliveryData,
            isDataLoading,
        } = this.props;

        const deliveryDate = options?.find(
            (item) => item.title === 'Delivery Date',
        );

        if (isDataLoading) {
            return (
                <div block="ProductPage" elem="SectionPlaceholder" />
            );
        }

        if (deliveryDate && getDeliveryDate) {
            return (
                <>
                    { type_id === ProductType.BUNDLE
                        && (
                            <form ref={ configFormRef }>
                                { this.renderBundleOptions() }
                            </form>
                        ) }
                    <Suspense fallback={ null }>
                        { availableDeliveryData && (
                            <>
                                { this.renderDeliveryDateItems(availableDeliveryData) }
                            </>
                        ) }
                    </Suspense>
                </>
            );
        }

        return (
            <form ref={ configFormRef }>
                { type_id === ProductType.BUNDLE && this.renderBundleOptions() }
                { this.renderCustomizableOptions() }
            </form>
        );
    }

    renderDeliveryDateItemsSlider(dataItems: DeliveryDataItem[] = []): ReactElement {
        const slider = {
            slides_to_display: 7,
            slides_to_scroll: 1,
            slides_to_display_mobile: 3.5,
            slides_to_scroll_mobile: 1,
            slides_to_display_tablet: 4.5,
            slides_to_scroll_tablet: 1,
            isHomeSlider: false,
        };

        return (
            <div block="DeliveryDate">
                <div block="DeliveryDate" elem="Label">{ __('Deliver on this day:') }</div>
                <div block="DeliveryDate" elem="Items">
                    <SlickSlider { ...slider }>
                        { dataItems.map(this.renderDeliveryDateItem.bind(this)) }
                    </SlickSlider>
                </div>
            </div>
        );
    }

    renderDeliveryDateItems(dataItems: DeliveryDataItem[] = []): ReactElement {
        if (!dataItems.length) {
            return null;
        }

        const {
            device: { isMobile },
            isTimerVisible,
            hideTimer,
            product,
            selectedProduct,
        } = this.props;
        const earliestDeliveryTime = selectedProduct ? selectedProduct.attributes?.earliest_delivery_time
            : product.attributes?.earliest_delivery_time;
        const earliestDeliveryDay = selectedProduct ? selectedProduct.attributes?.earliest_delivery_day
            : product.attributes?.earliest_delivery_day;

        const dateTime = europeTime(earliestDeliveryDay?.attribute_value, earliestDeliveryTime?.attribute_value);
        const isTomorrowAvailable = dataItems[0].is_available;

        if (isMobile) {
            return (
                <>
                    { this.renderDeliveryDateItemsSlider(dataItems) }
                    { isTimerVisible && hideTimer && isTomorrowAvailable && (
                        <ProductTimerComponent hideTimer={ hideTimer } time={ dateTime } />
                    ) }
                </>
            );
        }

        return (
            <>
                <div block="DeliveryDate">
                    <div block="DeliveryDate" elem="Label">{ __('Deliver on this day:') }</div>
                    <div block="DeliveryDate" elem="Items">
                        { dataItems.map(this.renderDeliveryDateItem.bind(this)) }
                    </div>
                </div>
                { isTimerVisible && hideTimer && isTomorrowAvailable && (
                    <ProductTimerComponent hideTimer={ hideTimer } time={ dateTime } />
                ) }
            </>

        );
    }

    renderDeliveryDateItem(item: DeliveryDataItem): ReactElement {
        const {delivery_date_availability_id, date, is_available} = item;
        const {
            product: {
                options,
            },
            handleDeliveryDate,
            selectedDeliveryDate,
        } = this.props;

        const deliveryDate = options?.find(
            (item) => item.title === 'Delivery Date',
        );

        if (handleDeliveryDate && deliveryDate) {
            const { uid } = deliveryDate;
            const isSelected = selectedDeliveryDate === date;
            const jsDate = new Date(date);
            const days = ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'];
            const weekDay = days[ jsDate.getDay() ];
            const formattedDeliveryDate = formattedDate(date);

            return (
                <button
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleDeliveryDate(e, date, uid) }
                  block="Button DeliveryDate"
                  elem="Item"
                  mods={ { isSelected } }
                  disabled={ !is_available }
                  key={ delivery_date_availability_id }
                >
                    { isSelected && <SelectedIcon /> }
                    <span>{ __(weekDay) }</span>
                    <span block="DeliveryDate" elem="Day">{ formattedDeliveryDate }</span>
                </button>
            );
        }

        return null;
    }

    renderBrand(): ReactElement {
        return null;
    }

    renderQuantityChanger(): ReactElement {
        const {
            maxQuantity,
            setQuantity,
            inStock,
            selectedProductScope,
            product: {
                type_id,
                stock_item,
                container_breakage,
            },
            selectedProduct,
        } = this.props;

        const qtyIncrements = {
            step: stock_item ? stock_item.qty_increments : 1,
        };

        if (selectedProductScope && container_breakage) {
            qtyIncrements.step = stock_item ? getProductIncrement(container_breakage, selectedProductScope) : 1;
        }

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        if (type_id === ProductType.CONFIGURABLE && selectedProduct && selectedProductScope) {
            const selectedProductContainerBreakage = selectedProduct.container_breakage;
            qtyIncrements.step = (selectedProduct.stock_item && selectedProductContainerBreakage)
                ? getProductIncrement(selectedProductContainerBreakage, selectedProductScope)
                : 1;
        }

        const isKg = qtyIncrements.step < 1;

        return (
            <Suspense fallback={ null }>
                <FieldContainer
                  type={ FieldType.NUMBER_WITH_CONTROLS }
                  attr={ {
                      id: 'item_qty',
                      name: 'item_qty',
                      defaultValue: qtyIncrements.step,
                      max: maxQuantity,
                      min: qtyIncrements.step,
                      step: qtyIncrements.step,
                  } }
                  validationRule={ {
                      inputType: ValidationInputTypeNumber.DECIMAL,
                      isRequired: true,
                      range: {
                          max: maxQuantity,
                      },
                  } }
                  isDisabled={ !inStock }
                  mix={ { block: this.className, elem: 'Qty' } }
                  events={ { onChange: setQuantity } }
                  validateOn={ ['onChange'] }
                  isKg={ isKg }
                  priceType={ selectedProductScope }
                />
            </Suspense>
        );
    }
}

export default ProductComponent;
