/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const ProductSliderConfigType = PropTypes.shape({
    autoplay: PropTypes.bool.isRequired,
    autoplaySpeed: PropTypes.number.isRequired,
    infinite: PropTypes.bool.isRequired,
    showArrows: PropTypes.bool.isRequired,
    showDots: PropTypes.bool.isRequired,
    dataCarouselMode: PropTypes.string.isRequired
});

export const PageBuilderStyles = PropTypes.shape({
    classes: PropTypes.string.isRequired,
    dataPbStyle: PropTypes.string.isRequired
});

export const ProductCardsPropsType = PropTypes.shape({
    siblingsHaveBrands: PropTypes.bool.isRequired,
    siblingsHavePriceBadge: PropTypes.bool.isRequired,
    siblingsHaveTierPrice: PropTypes.bool.isRequired,
    siblingsHaveConfigurableOptions: PropTypes.bool.isRequired
});
