import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {LOADING_TIME} from 'Route/CategoryPage/CategoryPage.config';
import {ProductPageContainerProps} from 'Route/ProductPage/ProductPage.type';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import history from 'Util/History';
import {debounce} from 'Util/Request/Debounce';
import {RootState} from 'Util/Store/Store.type';

import {
    ProductPageComponentProps,
    ProductPageContainerComponentPropKeys,
    ProductPageContainerState,
} from './ProductPage.type';

/** @namespace Steinkrueger/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
});

/** @namespace Steinkrueger/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Steinkrueger/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    state: ProductPageContainerState = {
        parameters: {},
        currentProductSKU: '',
        activeProduct: null,
    };

    componentDidUpdate(prevProps: ProductPageContainerProps): void {
        const {
            isOffline,
            productSKU,
            product,
            isSignedIn,
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: prevProduct,
            isSignedIn: prevIsSignedIn,
        } = prevProps;

        const { sku: stateSKU } = history?.location?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        if (JSON.stringify(product) !== JSON.stringify(prevProduct)) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        }

        if (isSignedIn !== prevIsSignedIn) {
            this.requestProduct();
        }

        this._addToRecentlyViewedProducts();
    }

    containerProps(): Pick<ProductPageComponentProps, ProductPageContainerComponentPropKeys> {
        const { isMobile, areReviewsEnabled } = this.props;
        const { parameters, activeProduct } = this.state;
        const { location } = history;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
            selectedProduct: activeProduct,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
